import { template as template_ee1cd23babbf4aeaba6794b0849dbaa4 } from "@ember/template-compiler";
const FKLabel = template_ee1cd23babbf4aeaba6794b0849dbaa4(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
