import { template as template_82cc9159298a4c26a3e8abfc38d2bf80 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_82cc9159298a4c26a3e8abfc38d2bf80(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
