import { template as template_fa4e9a23350740338f1b41c46cba8947 } from "@ember/template-compiler";
const FKControlMenuContainer = template_fa4e9a23350740338f1b41c46cba8947(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
